import toast from "react-hot-toast";
import { showToast } from "../Components/CommonComponents/ShowToast";
import { showFailed } from "../Components/CommonComponents/SweetAlert";
import axiosInstance from "../Interceptor/AxiosInstanceInterceptor";

export const fetchUserbyUCID = async (ucid) => {
  try {
    const response = await axiosInstance.get(`/user/uci/${ucid}`);
    const data = response.data;

    if (response.status === 200) {
      return data;
    } else {
      showFailed('Failed to fetch Bank data', `${response.error[0]}`);
    }
  } catch (error) {
    showFailed('Error retrieving user data:', error);
  }
};

export const fetchLoanDetails = async (branch) => {
  try {
    const response = await axiosInstance.get(`/loan?filter_by=branch&value=${branch}`);
    const data = response.data;
    if (response.status === 200) {
      return data;
    } else {
      showFailed('Failed to fetch Bank data', `${response.error[0]}`);
    }
  } catch (error) {
    showFailed('Error retrieving user data:', error?.response?.data?.error[0]);
  }
};

export const fetchNameByUcid = async (ucid) => {
  try {
    const response = await axiosInstance.get(`/user/uci/${ucid}`);
    const data = response.data;

    if (response.status === 200) {
      return data;
    } else {
      showFailed('Failed to fetch Bank data', `${response.error[0]}`);
    }
  } catch (error) {
    showFailed('Failed to load details:', error?.response?.data?.error[0]);
  }
};

export const addLoanData = async (formData, position, collateral) => {
  console.log("Loan Add Clicked -- >", formData, position, collateral);
  const payload = {
    bank: formData?.bankName,
    branch: formData?.branchName,
    identity_number: formData?.loanAccountNumber,
    amount: `${formData?.amount}`,
    interest: `${formData?.interest}`,
    tenure: {
      month: formData?.tenure,
    },
    type: formData?.loanType,
    collateral: collateral,
    positions: position,
  };
  try {
    const response = await axiosInstance.post(`/loan`, payload);
    const data = response.data;

    if (response.status === 200) {
      toast.success(`Loan data added successfully.`);
      return data;
    } else {
      showFailed('Failed', `${response.error[0]}`);
    }
  } catch (error) {
    showFailed('Failed to load Data', { error })
  }
};

export const fetchLoanById = async (loanId) => {
  try {
    const response = await axiosInstance.get(`/loan/${loanId}`);
    const data = response.data;

    if (response.status === 200) {
      return data;
    } else {
      showFailed('Failed to fetch Bank data', `${response.error[0]}`);
    }
  } catch (error) {
    showFailed('Failed to load details:', error?.response?.data?.error[0]);
  }
};


export const updateLoanData = async (formData, position, collateral, id) => {
  console.log("Loan Add Clicked -- >", formData, position, collateral);
  const payload = {
    bank: formData?.bankName,
    branch: formData?.branchName,
    identity_number: formData?.loanAccountNumber,
    amount: `${formData?.amount}`,
    interest: `${formData?.interest}`,
    tenure: {
      month: formData?.tenure,
    },
    type: formData?.loanType,
    collateral: collateral,
    positions: position,
  };
  try {
    const response = await axiosInstance.patch(`/loan/${id}`, payload);
    const data = response.data;

    if (response.status === 200) {
      toast.success(`Loan data updated successfully`);
      return data;
    } else {
      showFailed('Failed', `${response.error[0]}`);
    }
  } catch (error) {
    showFailed('Failed...', 'Not getting API data.');
    console.error("API Failed: ", error);
  }
};