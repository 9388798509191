import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { getDashboardData, getMaintenanceData, maintenanceSchedule } from '../../../../Services/Dashboard';
import { showFailed } from '../../../CommonComponents/SweetAlert';
import Loader from '../../../CommonComponents/Loader';
import ScheduleMaintenance from './ScheduleMaintenance';

const Home = () => {
  const [value, onChange] = useState(new Date());
  const [dashboardData, setDashboardData] = useState([]);
  // const [notificationData, setNotificationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [maintanenceSchedule, setMaintanenceSchedule] = useState([]);

  const formData = {
    date: '',
    schedule_start_time: '',
    schedule_end_time: '',
    description: ''
  }

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await getDashboardData();
      setDashboardData(data);
      setIsLoading(false);
    } catch (error) {
      showFailed('Failed...', `${error}`);
      setIsLoading(false);
    }
  };

  const fetchMaintenanceData = async () => {
    setIsLoading(true);
    try {
      const data = await getMaintenanceData();
      setMaintanenceSchedule(data);
      setIsLoading(false);
    } catch (error) {
      showFailed('Failed...', `${error}`);
      setIsLoading(false);
    } finally {
      setIsPreviewOpen(false);
      setIsLoadingUpdate(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchMaintenanceData();
  }, []);

  const handleSubmit = (values) => {
    setIsLoadingUpdate(true)
    try {
      maintenanceSchedule(values);
      setTimeout(async () => {
        await fetchMaintenanceData();
      }, 2000);
    } catch (error) {
      console.error('Error adding user:', error);
      showFailed("Failed to add user. Please try again later.");
    }
  }

  const scheduleMaintenance = async () => {
    setIsPreviewOpen(true);
  }

  const handleClose = async () => {
    console.log("first")
    setIsPreviewOpen(false);
  }

  const data = [
    {
      name: 'Total Users',
      loans: `${dashboardData?.totalUser || ''}`,
      percent: '+3.48%'
    },
    {
      name: 'New Users in Last Month',
      loans: `${dashboardData?.lastMonthUser || ''}`,
      percent: '+3.48%'
    },
    {
      name: 'Total Banks',
      loans: `${dashboardData?.noOfBankRegistered || ''}`,
      percent: '+3.48%'
    },
    {
      name: 'Total Loans',
      loans: `${dashboardData?.linkLoan || ''}`,
      percent: '+3.48%'
    },
    {
      name: 'Delivery Defaults',
      loans: `${dashboardData?.devlieryDefault}`,
      percent: '+3.48%'
    },
    {
      name: 'Notice Delivery Done',
      loans: `${dashboardData?.loanDefaultRemainderCount || ''}`,
      percent: '+3.48%'
    },
    {
      name: 'Inquiry ( Unread )',
      loans: `${dashboardData?.NpaDefaultRemainderCount || 'not available'}`,
      percent: '+3.48%'
    },
    {
      name: 'Maintenance',
      loans: `${maintanenceSchedule[0]?.date}`,
      percent: '+3.48%'
    },
    {
      name: 'Sales',
      loans: `${dashboardData?.totalSales}`,
      percent: '+3.48%'
    },
    {
      name: 'Invoice Payment Pending',
      loans: `${dashboardData?.pendingPayment || ''}`,
      percent: '+3.48%'
    },
    // {
    //   name: 'Schedule maintenance',
    //   loans: `${dashboardData?.scheduleMaintainence || '08-10-2024'}`,
    //   percent: '+3.48%'
    // }
  ]

  const message = [
    {
      name: 'Surya Pratap',
      message: 'This process helps identify images on a webpage that are not loading images on a webpage that are not loading',
      time: '5 Min',
      status: 'unread'
    },
    {
      name: 'Rahul Sharma',
      message: 'This process helps identify images on a webpage that are not loading images on a webpage that are not loading',
      time: '5 Min',
      status: 'read'
    }
  ]

  return (
    <>
      <div className="flex justify-end bg-bgclr4 min-h-screen py-10">
        <div className='rounded-md w-3/4 ml-32'>
          {isLoading ? (
            <div className="-mt-36 -ml-64">
              <Loader />
            </div>
          ) : (
            <div className="w-[975px]">
              <div>
                <div className="text-[24px] font-[600] my-5 text-bgclr6">
                  <h1>Overview</h1>
                </div>
                <div className='grid grid-cols-3 gap-5 mr-3'>
                  {data.map((item, index) => (
                    <div className='bg-bgclr9 pl-5 pr-1 py-5 rounded-3xl shadow-xl'>
                      <p className='text-[14px] text-[#A0AEC0]'>{item.name}</p>
                      <h3 className='text-[18px] text-[#2D3748] font-[700]'>{item.loans}</h3>
                      {item.name === "Maintenance" ? (
                        <button onClick={() => scheduleMaintenance()} className='flex items-end mt-5 text-blue-500 underline'>Schedule Now</button>
                      ) : (
                        <div className='flex items-end mt-5'>
                          <h2 className='text-[14px] text-[#48BB78] font-[700]'>{item.percent}</h2>
                          <p className='text-[14px] text-[#A0AEC0] ml-3'>Since last month</p>
                        </div>
                      )}

                    </div>
                  ))}
                </div>
                <div>
                </div>
              </div >
              <div className='flex mt-10 gap-5'>
                <div className='w-[60%] rounded-xl bg-bgclr9 shadow-lg'>
                  <div className="text-[24px] px-3 font-[600] py-3 text-bgclr6 border-b">
                    <h1>Notifications</h1>
                  </div>
                  <div>
                    {message?.map((item, index) => (
                      <div className={`pl-5 pr-1 py-5 border-b ${item.status === 'unread' ? 'bg-bgclr8' : 'bg-bgclr9'}`}>
                        <div className='flex justify-between'>
                          <h2 className='text-[18px] text-bgclr font-[700]'>{item.name}{item.first_name} {item.middle_name} {item.last_name}</h2>
                          <p className='text-[14px] text-[#A0AEC0] mx-3'>{item.time}</p>
                        </div>
                        <p className='text-[16px] text-[#A0AEC0] mt-3 mr-10'>{item.message}</p>
                      </div>
                    ))}
                    <div className='flex justify-center rounded-b-xl bg-[#E0E6FF]'>
                      <button className='py-3 text-[16px] text-bgclr font-semibold'>
                        View All
                      </button>
                    </div>
                  </div>
                </div>
                <div className='w-[40%] rounded-xl bg-bgclr9 shadow-lg'>
                  <div>
                    <div className="text-[24px] px-3 font-[600] py-3 text-bgclr6">
                      <h1>Calender</h1>
                    </div>
                    <div className='flex justify-center'>
                      <Calendar
                        onChange={onChange}
                        value={value}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div >
          )}
        </div >
      </div>

      {isPreviewOpen && (
        <ScheduleMaintenance
          handleClose={handleClose}
          isPreviewOpen={isPreviewOpen}
          formData={formData}
          handleSubmit={handleSubmit}
          isLoadingUpdate={isLoadingUpdate}
        />
      )}
    </>
  )
}

export default Home