import React, { useState } from 'react'
import { Button1, Button3 } from '../../../CommonComponents/Button';
import { Loader } from '../../Elements/UpdateLoader';
import DataTable from '../../../CommonComponents/DataTable';
import Pagination from '../../../CommonComponents/Pagination';
import EmptyDataAlert from '../../../CommonComponents/EmptyDataAlert';
import ScheduleCamp from './ScheduleCamp';

const WhatsappTable = () => {

    const [whatsappData, setWhatsappData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isPreviewOpen, setPreviewOpen] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [pagination, setPagination] = useState(null);
    const [error, setError] = useState(false);
    const [operation, setOperation] = useState('add');
    const [userId, setUserId] = useState();

    const formData = {
        campName: '',
        userType: '',
        userData: '',
        templateType: '',
        template: ''
    }   

    const handleWhatsappSchedule = () => {
        console.log("Add User Click");
        setOperation('Add');
        setPreviewOpen(true);
    }

    const handleEdit = (_id) => {
        console.log("Edit Action triggerd", _id);
        // setOperation('update');
        setPreviewOpen(true);
        // setUserId(_id)
    }

    const handleClose = async () => {
        setPreviewOpen(false);
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Campaign Name',
                accessor: ''
            },
            {
                Header: 'Message Body',
                accessor: ''
            },
            {
                Header: 'Phone Number',
                accessor: ''
            },
            {
                Header: 'Sent time',
                accessor: ''
            },
            {
                Header: 'Sent by',
                accessor: ''
            },
            {
                Header: 'Status',
                accessor: ''
            },
            {
                Header: 'Action',
                accessor: '',
                Cell: ({ row }) => (
                    <div className="flex my-2">
                        <Button3
                            label="Edit"
                            onClick={() => handleEdit()}
                        />
                    </div>
                )
            },
        ],
        []);

    const data = React.useMemo(() => whatsappData);

    return (
        <>
            <div className="flex justify-end bg-gray-200 min-h-screen py-10">
                <div className="rounded-md w-3/4 ml-32">
                    <div className='flex'>
                        <h1 className='text-3xl font-bold'>Whatsapp Scheduler</h1>
                    </div>
                    <div className='flex gap-5 mt-5'>
                        <div className='flex mt-5'>
                            <Button1 label="+Add More"
                                onClick={handleWhatsappSchedule}
                            />
                        </div>
                    </div>

                    <div className='flex'>
                        <input
                            type="text"
                            placeholder="Search by Name/Email"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="border border-bgclr3 rounded-md w-[890px] pl-6 pr-5 py-1 mr-2 mb-4 mt-4"
                        />
                    </div>
                    {!error ? (
                        <div>
                            {fetchLoading ? (
                                <div className="-mt-36 -ml-32">
                                    <Loader />
                                </div>
                            ) : (
                                <div className="w-[975px] mt-6 mb-20">
                                    <div className="pt-3 overflow-x-auto mb-10">
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                        />
                                    </div>
                                    <Pagination
                                    // hasPrevPage={pagination.hasPrevPage}
                                    // hasNextPage={pagination.hasNextPage}
                                    // goToPrevPage={goToPrevPage}
                                    // goToNextPage={goToNextPage}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='mt-10'>
                            <EmptyDataAlert title="Add More Whatsapp Campaign." />
                        </div>
                    )}
                </div>
            </div>

            {isPreviewOpen && (
                <ScheduleCamp
                    isPreviewOpen={isPreviewOpen}
                    onClose={() => setPreviewOpen(false)}
                    handleClose={handleClose}
                    // handleSubmit={handleSubmit}
                    // handleUpdate={handleUpdate}
                    formData={formData}
                // isLoadingUpdate={isLoadingUpdate}
                // userId={userId}
                // operation={operation}
                />
            )}

        </>
    )
}

export default WhatsappTable