import React, { useState, useEffect } from 'react';

const SearchInput = ({ placeholder, value, onChange, debounceTime = 500 }) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      onChange(localValue);
    }, debounceTime);

    return () => {
      clearTimeout(handler);
    };
  }, [localValue, debounceTime, onChange]);

  return (
    <input
      type="text"
      placeholder={placeholder}
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      className="border border-bgclr3 rounded-md w-full pl-6 pr-5 py-1 mb-4 mt-4"
    />
  );
};

export default SearchInput;
