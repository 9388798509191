import React from 'react'
import Popup from 'reactjs-popup';
import { Loader } from '../../Elements/UpdateLoader';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { MdAccessTimeFilled, MdDateRange } from 'react-icons/md';
import { Button1 } from '../../../CommonComponents/Button';
import * as Yup from "yup";
import { FaSpinner } from 'react-icons/fa';

const ScheduleMaintenance = ({ isPreviewOpen, handleClose, isLoadingUpdate, handleSubmit, isEditing, formData }) => {


    const validationSchema = Yup.object({
        date: Yup.string().required('Date is Required'),
        schedule_start_time: Yup.string().required('Start time is Required'),
        schedule_end_time: Yup.string().required('End time is Required'),
        description: Yup.string().required('Description is Required'),
    });

    return (
        <>
            <Popup open={isPreviewOpen} modal closeOnDocumentClick={false}>
                {(close) => (
                    <div
                        className="bg-gray-500 fixed inset-0 flex bg-opacity-50  h-screen justify-center items-center overflow-y-scroll"
                        style={{ WebkitOverflowScrolling: "touch", scrollbarWidth: "none" }}
                    >
                        <div className="flex container justify-center items-center p-8 rounded-md mx-auto min-h-screen max-h-screen ">
                            <div className="w-[656px] mx-auto">
                                <Formik
                                    initialValues={formData}
                                    validationSchema={validationSchema}
                                    onSubmit={values => handleSubmit(values)}
                                >
                                    {(formikProps) => (
                                        <Form>
                                            <div className="bg-bg-form h-[366px] p-[22px] rounded-[14px] text-tbl-txt-clr">
                                                <div className="flex justify-between">
                                                    <h2 className="text-xl font-semibold">
                                                        {isEditing
                                                            ? "Update Schedule Maintenance"
                                                            : "Schedule Maintenance"}
                                                    </h2>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            handleClose();
                                                            close();
                                                        }}
                                                        className="text-xl font-bold"
                                                        disabled={isLoadingUpdate}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                                <div className="flex w-[50%] items-start gap-20 mt-[30px] h-[58px]">
                                                    <label
                                                        htmlFor="date"
                                                        className="flex items-center gap-2 text-[16px] font-medium"
                                                    >
                                                        <MdDateRange size={20} />Date<span className="text-red-800">*</span>
                                                    </label>
                                                    <div>
                                                        <Field
                                                            type="date"
                                                            id="date"
                                                            name="date"
                                                            className="w-[141px] h-[28px] text-[14px] border border-[#000000] border-opacity-[40%] rounded-md "
                                                        />
                                                        <ErrorMessage
                                                            name="date"
                                                            component="div"
                                                            className="text-red-600 text-sm"
                                                        />
                                                    </div>
                                                </div>

                                                <div className='flex justify-between gap-10 items-start h-[58px]'>
                                                    <div className="flex w-[50%] gap-4 items-start h-[58px]">
                                                        <label
                                                            htmlFor="schedule_start_time"
                                                            className="flex items-center gap-1 text-[16px] font-medium"
                                                        >
                                                            <MdAccessTimeFilled size={20} />Schedule Time<span className="text-red-800">*</span>
                                                        </label>
                                                        <div>
                                                            <Field
                                                                type="time"
                                                                id="schedule_start_time"
                                                                name="schedule_start_time"
                                                                className="w-[141px] h-[28px] text-[14px] border border-[#000000] border-opacity-[40%] rounded-md "
                                                            />
                                                            <ErrorMessage
                                                                name="schedule_start_time"
                                                                component="div"
                                                                className="text-red-600 text-sm"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex gap-5 items-start h-[58px]">
                                                        <label
                                                            htmlFor="schedule_end_time"
                                                            className="flex items-center gap-1 text-[16px] font-medium"
                                                        >
                                                            End Time<span className="text-red-800">*</span>
                                                        </label>
                                                        <div>
                                                            <Field
                                                                type="time"
                                                                id="schedule_end_time"
                                                                name="schedule_end_time"
                                                                className="w-[141px] h-[28px] text-[14px] border border-[#000000] border-opacity-[40%] rounded-md "
                                                            />
                                                            <ErrorMessage
                                                                name="schedule_end_time"
                                                                component="div"
                                                                className="text-red-600 text-sm"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex justify-between gap-8 items-start h-[58px]">
                                                    <label
                                                        htmlFor="description"
                                                        className="flex items-start gap-2 text-[16px] font-medium"
                                                    >
                                                        <MdDateRange size={20} />Description<span className="text-red-800">*</span>
                                                    </label>
                                                    <div>
                                                        <Field
                                                            as="textarea"
                                                            id="description"
                                                            name="description"
                                                            className="w-[457px] h-[86px] p-2 text-[14px] border border-[#000000] border-opacity-[40%] rounded-md "
                                                        />
                                                        <ErrorMessage
                                                            name="description"
                                                            component="div"
                                                            className="text-red-600 text-sm"
                                                        />
                                                    </div>
                                                </div>

                                                <div className='flex justify-between mt-[55px]'>
                                                    <h1 className='mt-2'>
                                                        Like to know the secrets of to know the winning Dynasty?
                                                    </h1>
                                                    <button
                                                        type="submit"
                                                        className="px-12 py-2.5 text-white bg-bgclr rounded-md font-bold hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200"
                                                        disabled={isLoadingUpdate}
                                                    >
                                                        {isLoadingUpdate ? (
                                                            <FaSpinner className="animate-spin text-3xl" />
                                                        ) : (
                                                            "Schedule"
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                )}
            </Popup>
        </>
    )
}

export default ScheduleMaintenance