import React, { useState } from 'react'
import Popup from 'reactjs-popup';
import { Loader } from '../../Elements/UpdateLoader';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { FaSpinner } from 'react-icons/fa';

const ScheduleCamp = ({ isPreviewOpen, onClose, userId, roles, operation, handleClose, handleSubmit, handleUpdate, formData, isLoadingUpdate }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updateUserValue, setUpdatedUserValues] = useState([]);
    const [selectedOption, setSelectedOption] = useState('comma');

    const handleOptionChange = (value) => {
        setSelectedOption(value);
    }

    return (
        <>
            <Popup open={isPreviewOpen} modal closeOnDocumentClick={false}>
                {(close) => (
                    <div
                        className="bg-gray-500 fixed inset-0 flex bg-opacity-50  h-screen justify-center items-center overflow-y-scroll"
                        style={{ WebkitOverflowScrolling: "touch", scrollbarWidth: "none" }}
                    >
                        {isLoading ? (
                            Loader
                        ) : (
                            <div className="flex container justify-center items-center p-8 rounded-md mx-auto min-h-screen max-h-screen ">
                                <div className="w-[950px] mx-auto">
                                    <Formik
                                        initialValues={isEditing ? updateUserValue : formData}
                                    // validationSchema={validationSchema}
                                    // onSubmit={values => isEditing ? handleUpdate(values, updateUserValue.permission) : handleSubmit(values, updateUserValue.permission)}
                                    >
                                        {(formikProps) => (
                                            <Form>
                                                <div className="bg-bg-form px-16 py-16 text-tbl-txt-clr">
                                                    <div className="flex justify-end">
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleClose();
                                                                close();
                                                                setUpdatedUserValues(null);
                                                            }}
                                                            className="text-2xl font-bold"
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                    <h2 className="text-xl font-semibold pb-6">
                                                        {isEditing
                                                            ? "Update Whatsapp Campaign"
                                                            : "Schedule Whatsapp Campaign"}
                                                    </h2>
                                                    <div className="mb-6">
                                                        <label htmlFor="campName" className="block text-sm font-medium" >
                                                            Campaign Name<span className="text-red-600 font-bold">*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="campName"
                                                            name="campName"
                                                            placeholder="Enter Campaign Name"
                                                            className="input-style"
                                                        />
                                                        <ErrorMessage name="campName" component="div" className="text-red-600 font-semibold" />
                                                    </div>
                                                    <div className="mb-6">
                                                        <label htmlFor="userType" className="block text-sm font-medium" >
                                                            Choose User<span className="text-red-600 font-bold">*</span>
                                                        </label>
                                                        <div className='flex gap-7 mt-2'>
                                                            <div className="flex gap-2">
                                                                <Field
                                                                    type="radio"
                                                                    id="commaOption"
                                                                    name="userType"
                                                                    value="comma"
                                                                    checked={selectedOption === 'comma'}
                                                                    onClick={() => handleOptionChange("comma")}
                                                                    className=""
                                                                />
                                                                <label htmlFor="commaOption" className="block text-sm font-medium" >
                                                                    Comma Separated Phone no.
                                                                </label>
                                                            </div>
                                                            <div className="flex gap-2">
                                                                <Field
                                                                    type="radio"
                                                                    id="predefinedOption"
                                                                    name="userType"
                                                                    value="comma"
                                                                    checked={selectedOption === 'predefined'}
                                                                    onClick={() => handleOptionChange("predefined")}
                                                                    className=""
                                                                />
                                                                <label htmlFor="predefinedOption" className="block text-sm font-medium" >
                                                                    Pre-defined List
                                                                </label>
                                                            </div>
                                                            <div className="flex gap-2">
                                                                <Field
                                                                    type="radio"
                                                                    id="csvOption"
                                                                    name="userType"
                                                                    value="csv"
                                                                    checked={selectedOption === 'csv'}
                                                                    onClick={() => handleOptionChange("csv")}
                                                                    className=""
                                                                />
                                                                <label htmlFor="csvOption" className="block text-sm font-medium" >
                                                                    Upload CSV
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {selectedOption === "comma" && (
                                                            <div className="mt-2">
                                                                <Field
                                                                    type="text"
                                                                    id="userData"
                                                                    name="userData"
                                                                    placeholder="Enter Comma Separated Phone no."
                                                                    className="input-style"
                                                                />
                                                                <ErrorMessage name="userData" component="div" className="text-red-600 font-semibold" />
                                                            </div>
                                                        )}
                                                        {selectedOption === "predefined" && (
                                                            <div className="mt-2">
                                                                <Field
                                                                    as="select"
                                                                    id="userData"
                                                                    name="userData"
                                                                    className="mt-1 p-2 w-[500px] border rounded-sm focus:border-gray-400 ring-1 ring-gray-300"
                                                                >
                                                                    <option value="">Select User</option>
                                                                    <option value="banking">Option 1</option>
                                                                    <option value="legal">Option 2</option>
                                                                    <option value="document">Option 3</option>
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="userData"
                                                                    component="div"
                                                                    className="text-red-600 font-semibold"
                                                                />
                                                            </div>
                                                        )}
                                                        {selectedOption === "csv" && (
                                                            <div className="mt-2">
                                                                <div className='flex items-center gap-5'>
                                                                    <Field
                                                                        type="file"
                                                                        id="userData"
                                                                        name="userData"
                                                                        className="input-style w-[300px]"
                                                                    />
                                                                    <button className='bg-bgclr mt-1 text-[#FDFDFD] rounded-[8px] w-[94px] h-[50px]'>
                                                                        Upload
                                                                    </button>
                                                                </div>
                                                                <ErrorMessage name="userData" component="div" className="text-red-600 font-semibold" />
                                                            </div>
                                                        )}

                                                    </div>

                                                    <div className='flex justify-between gap-5 mb-6'>
                                                        <div className="w-[380px]">
                                                            <label htmlFor="templateType" className="block text-sm font-medium" >
                                                                Campaign Template<span className="text-red-600 font-bold">*</span>
                                                            </label>
                                                            <Field
                                                                as="select"
                                                                id="templateType"
                                                                name="templateType"
                                                                placeholder="Enter Campaign Template"
                                                                className="input-style"
                                                            >
                                                                <option value="">Select Template Type</option>
                                                                <option value="banking">Option 1</option>
                                                                <option value="legal">Option 2</option>
                                                                <option value="document">Option 3</option>
                                                            </Field>
                                                            <ErrorMessage name="templateType" component="div" className="text-red-600 font-semibold" />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="template" className="block text-sm font-medium" >
                                                                Template Design<span className="text-red-600 font-bold">*</span>
                                                            </label>
                                                            <Field
                                                                as="textarea"
                                                                id="template"
                                                                name="template"
                                                                className="input-style w-[425px] h-[260px]"
                                                            />
                                                            <ErrorMessage name="template" component="div" className="text-red-600 font-semibold" />
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-center mt-5">
                                                        <button
                                                            type="submit"
                                                            className="px-12 py-2 text-white bg-bgclr w-full rounded-md font-bold hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200"
                                                        >
                                                            {isLoadingUpdate ? (
                                                                <FaSpinner className="animate-spin text-3xl" />
                                                            ) : (
                                                                "Submit"
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Popup>

        </>
    )
}

export default ScheduleCamp