import React, { useState, useEffect } from 'react'
import { Popup } from 'reactjs-popup'
import { ErrorMessage, Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FaSpinner } from 'react-icons/fa'
import { Loader } from '../../Elements/UpdateLoader'
import { getBankUserById } from '../../../../Services/BankCredential'

const CredentialAction = ({
  isPreviewOpen,
  bankId,
  options,
  selectedBank,
  fetchBranchesData,
  handleDropdownChange,
  operation,
  handleClose,
  handleSubmit,
  handleUpdate,
  formData,
  isLoadingUpdate
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [updateUserValue, setUpdatedUserValues] = useState([])

  useEffect(() => {
    if (operation === 'update') {
      const getUserById = async () => {
        setIsLoading(true)
        try {
          const data = await getBankUserById(bankId)
          setUpdatedUserValues({
            email: data?.email || '',
            name: data?.name || '',
            status: data?.status || '',
            bankName: data?.bank?._id || '',
            branchName: data?.branch?._id || ''
          })
          fetchBranchesData(data?.bank?._id)
        } catch (error) {
          console.error('Error retrieving user data:', error)
        } finally {
          setIsLoading(false)
        }
      }
      getUserById()
      setIsEditing(true)
    }
  }, [bankId])

  const validationSchema = Yup.object().shape({
    bankName: Yup.string().required('Bank name is required'),
    branchName: Yup.string().required('Branch name is required'),
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: isEditing
      ? Yup.string()
      : Yup.string()
          .min(6, 'Password must be at least 6 characters')
          .required('Password is required')
  })

  useEffect(() => {
    console.log('Update User Value is showing yha pe.......:', updateUserValue)
  }, [updateUserValue])

  return (
    <>
      <Popup open={isPreviewOpen} modal closeOnDocumentClick={false}>
        {close => (
          <div
            className='bg-gray-500 fixed inset-0 flex bg-opacity-50 h-screen justify-center items-center overflow-y-scroll'
            style={{ WebkitOverflowScrolling: 'touch', scrollbarWidth: 'none' }}
          >
            {isLoading ? (
              Loader
            ) : (
              <div className='flex container justify-center items-center p-8 rounded-md mx-auto min-h-screen max-h-screen '>
                <div className='w-[500px] mx-auto'>
                  {isEditing && !updateUserValue.email ? (
                    <p>Loading user data...</p>
                  ) : (
                    <Formik
                      initialValues={isEditing ? updateUserValue : formData}
                      validationSchema={validationSchema}
                      onSubmit={isEditing ? handleUpdate : handleSubmit}
                      enableReinitialize
                    >
                      {formikProps => (
                        <Form>
                          <div className='bg-bg-form px-16 py-16 mt-20 text-tbl-txt-clr'>
                            <div className='flex justify-end'>
                              <button
                                onClick={() => {
                                  handleClose()
                                  close()
                                  setUpdatedUserValues(null)
                                }}
                                className='text-2xl font-bold'
                              >
                                X
                              </button>
                            </div>
                            <h2 className='text-xl font-semibold pb-6'>
                              {isEditing ? 'Update Bank User' : 'Add Bank User'}
                            </h2>
                            <div className='mb-4 mt-4'>
                              <label htmlFor='bankName' className='pr-12'>
                                Select Bank
                              </label>
                              <Field
                                as='select'
                                id='bankName'
                                name='bankName'
                                className='input-style rounded-md'
                                onChange={e => {
                                  formikProps.setFieldValue(
                                    'bankName',
                                    e.target.value
                                  ) // Explicitly set value
                                  handleDropdownChange(e, e.target.value)
                                }}
                              >
                                <option value=''>Select a bank</option>
                                {options?.map(option => (
                                  <option key={option?._id} value={option?._id}>
                                    {option?.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name='bankName'
                                component='div'
                                className='text-red-600 font-semibold'
                              />
                            </div>
                            <div className='mb-4 mt-4'>
                              <label htmlFor='branchName' className='pr-12'>
                                Select Branch
                              </label>
                              <Field
                                as='select'
                                id='branchName'
                                name='branchName'
                                className='input-style rounded-md'
                              >
                                <option value=''>Select a branch</option>
                                {selectedBank?.map(option => (
                                  <option key={option?._id} value={option?._id}>
                                    {option?.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name='branchName'
                                component='div'
                                className='text-red-600 font-semibold'
                              />
                            </div>
                            <div className='mb-6'>
                              <label
                                htmlFor='name'
                                className='block text-sm font-medium text-txtclr2'
                              >
                                Name
                                <span className='text-red-600 font-bold'>
                                  *
                                </span>
                              </label>
                              <Field
                                type='text'
                                placeholder='Enter Name'
                                id='name'
                                name='name'
                                className='input-style'
                              />
                              <ErrorMessage
                                name='name'
                                component='div'
                                className='text-red-600 font-semibold'
                              />
                            </div>
                            <div className='mb-6'>
                              <label
                                htmlFor='email'
                                className='block text-sm font-medium'
                              >
                                Email ID
                                <span className='text-red-600 font-bold'>
                                  *
                                </span>
                              </label>
                              <Field
                                type='text'
                                id='email'
                                name='email'
                                placeholder='Enter email Id'
                                className='input-style'
                              />
                              <ErrorMessage
                                name='email'
                                component='div'
                                className='text-red-600 font-semibold'
                              />
                            </div>

                            {!isEditing && (
                              <div className='mb-6'>
                                <label
                                  htmlFor='password'
                                  className='block text-sm font-medium text-txtclr2'
                                >
                                  Password
                                  <span className='text-red-800'>*</span>
                                </label>
                                <Field
                                  type='text'
                                  placeholder='Enter Bank Password'
                                  id='password'
                                  name='password'
                                  className='input-style'
                                />
                                <ErrorMessage
                                  name='password'
                                  component='div'
                                  className='text-red-600 font-semibold'
                                />
                              </div>
                            )}
                            {isEditing && (
                              <div className='mb-6'>
                                <label
                                  htmlFor='status'
                                  className='block text-sm font-medium text-txtclr2'
                                >
                                  Status<span className='text-red-800'>*</span>
                                </label>
                                <Field
                                  as='select'
                                  placeholder='Enter Bank Password'
                                  id='status'
                                  name='status'
                                  className='input-style'
                                >
                                  <option value='active'>Active</option>
                                  <option value='inactive'>Inactive</option>
                                </Field>
                              </div>
                            )}
                            <div className='flex justify-center '>
                              <button
                                type='submit'
                                className='px-12 mt-8 py-2 text-txt-lt-clr bg-bgclr2 rounded-full font-bold hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200'
                              >
                                {isLoadingUpdate ? (
                                  <FaSpinner className='animate-spin text-3xl' />
                                ) : (
                                  'Submit'
                                )}
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Popup>
    </>
  )
}

export default CredentialAction
